import QuillToolbarDropDown from "../../dynamicquilltools";

const Link = Quill.import("formats/link");
const dropDownItems = {
  "Select class" : "",
  "Small button": "button small",
  "Button": "button",
  "Large button": "button large"
}

export const buttonizer = new QuillToolbarDropDown({
    label: Object.keys(dropDownItems)[0],
    rememberSelection: false
})

buttonizer.setItems(dropDownItems)

buttonizer.currentSelectionLabel = Object.keys(dropDownItems)[0]
buttonizer.dropDownItems = dropDownItems

buttonizer.onSelect = (_label, value, quill) => {
  // Do whatever you want with the new dropdown selection here
  const { index, length } = quill.selection.savedRange;
  const leaf = quill.getLeaf(index);
  if (!leaf || !leaf[0]) {
    quill.setSelection(index, length);
    return;
  }

  const textBlot = leaf[0];
  const link = textBlot.parent;
  if (link instanceof Link) {
    const linkNode = link.domNode;

    if (value.length > 0) {
      linkNode.setAttribute("class", value);
    } else {
      linkNode.removeAttribute("class");
    }
  }

  // quill.setContents(quill.getContents());
  quill.setSelection(index, length);
}
