import { linkDropdown } from "./link_dropdown"
import { buttonizer } from "./buttonizer"

const showDropdown = (dropdown, quill) => {
  dropdown.attach(quill);
  const index = quill.getSelection().index;
  let domNode = quill.getLeaf(index)[0].domNode;
  Object.entries(dropdown.dropDownItems).forEach(e => {
    if (dropdown.options.label === "Link target" && e[1] === domNode.parentNode.target) {
      dropdown.dropDownEl.firstChild.dataset.label = e[0]
      dropdown.currentSelectionLabel = e[0]
    } else if (dropdown.options.label === "Select class" && e[1] === domNode.parentNode.className) {
      dropdown.dropDownEl.firstChild.dataset.label = e[0]
      dropdown.currentSelectionLabel = e[0]
    }
  })
}

const hideDropdown = (dropdown, quill) => {
  if (dropdown.toolbarEl && dropdown.toolbarEl.hasChildNodes()) {
    dropdown.toolbarEl.childNodes.forEach(e => {
      if (e.firstChild && e.firstChild.firstChild.dataset.label === dropdown.currentSelectionLabel) {
        dropdown.detach(quill);
      }
    })
  }
}

export const dropdowns = (quill, range) => {
  const format = quill.getFormat(range);

  if (format.link) {
    showDropdown(linkDropdown, quill);
    showDropdown(buttonizer, quill);
  } else {
    hideDropdown(linkDropdown, quill);
    hideDropdown(buttonizer, quill);
  }
}
